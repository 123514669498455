<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="短视频分类：">
        <div class="classify_box" v-for="(item, index) in ruleForm.classify_list" :key="index">
          <el-input :disabled="!item.flag" v-model="item.classify_name" placeholder="请输入分类名称"></el-input>
          排序：
          <el-input style="width: 100px" :disabled="!item.flag" v-model="item.sort" placeholder="排序"></el-input>
          <img @click="delClassify(item, index)" class="closeImg" v-if="ruleForm.classify_list.length > 1" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png" alt="" />
          <el-button type="text" v-if="item.id && !item.flag" @click="item.flag = true">编辑</el-button>
          <el-button type="text" v-if="item.flag" @click="clickComplete(item)">完成</el-button>
        </div>
        <div>
          <el-button type="text" @click="addClassify">+ 添加短视频分类</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        classify_list: [
          {
            classify_name: '',
            sort: 99,
            flag: true, // true -> 可以编辑
          },
        ],
      },
      rules: {},
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    // 获取短视频分类列表
    getClassifyList() {
      this.$axios.post(this.$api.Tikmall.getVideoClassifyList).then(res => {
        if (res.code === 0) {
          if (res.result.length) {
            this.ruleForm.classify_list = res.result;
            this.ruleForm.classify_list.map(item => {
              this.$set(item, 'flag', false);
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击添加分类按钮
    addClassify() {
      let len = this.ruleForm.classify_list.length;
      if (!this.ruleForm.classify_list[len - 1].id) {
        this.$message.warning('每次只能添加一个分类');
        return;
      }
      this.ruleForm.classify_list.push({
        classify_name: '',
        sort: 99,
        flag: true,
      });
    },
    // 点击完成按钮
    clickComplete(data) {
      if (data.classify_name === '') {
        this.$message.warning('分类名称不能为空');
        return;
      }
      if (data.id) {
        this.updateClassify(data);
      } else {
        this.addClassifyFn(data);
      }
    },
    // 修改短视频分类
    updateClassify(data) {
      let obj = {
        id: data.id,
        classify_name: data.classify_name,
        sort: data.sort,
      };
      if (this.is_ing) return;
      this.$axios.post(this.$api.Tikmall.editVideoClassify, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('修改成功');
          data.flag = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 添加短视频分类
    addClassifyFn(data) {
      let obj = {
        classify_name: data.classify_name,
        sort: data.sort,
      };
      this.$axios.post(this.$api.Tikmall.addVideoClassify, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('添加成功');
          data.flag = false;
          this.$set(data, 'id', res.result);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除短视频分类
    delClassify(data, index) {
      if (!data.id) {
        this.ruleForm.classify_list.splice(index, 1);
        return;
      }
      this.$axios
        .post(this.$api.Tikmall.delVideoClassify, {
          id: data.id,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.ruleForm.classify_list.splice(index, 1);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  .el-form {
    .classify_box {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .el-input {
        width: 400px;
        margin-right: 20px;
      }
      .closeImg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 20px;
      }
    }
    .classify_box:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
